import Vue from 'vue'
export function clearCookies(){
    window.localStorage.clear()
    window.sessionStorage.clear()
    Vue.$cookies.remove('token', '/', '.ecmoho.com')
    Vue.$cookies.remove('token', '/') 
}

export function createResponseCode(docItem) {
    const method = docItem.name.replace(/\./g, "_");
    const responseParameters = docItem.responseParameters;
    var bizResult = buildResult(responseParameters);
    var json =
        "{\n" +
        '    "' +
        method +
        '_response": {\n' +
        '        "code": "10000",\n' +
        '        "msg": "Success",\n' +
        bizResult +
        "    }" +
        "}";
    json = formatJson(json);
    var errorJson =
        "{\n" +
        '    "' +
        method +
        '_response": {\n' +
        '        "code": "20000",\n' +
        '        "msg": "Service is temporarily unavailable",\n' +
        '        "sub_code": "isp.unknown-error",\n' +
        '        "sub_msg": "服务暂不可用"\n' +
        "    }" +
        "}";
    errorJson = formatJson(errorJson)
    return {
        json,
        errorJson
    }
}

function buildResult(parameters) {
    var result = [];
    for (var i = 0; i < parameters.length; i++) {
        var parameter = parameters[i];
        result.push(
            '"' + parameter.name + '": ' + buildExample(parameter)
        );
    }
    return result.join(",");
}
function buildExample(parameter) {
    var refs = parameter.refs || '';
    if (refs.length) {
        // {...}
        var content = "{" + buildResult(refs) + "}";
        if (parameter.type == "array") {
            // [{...}]
            content = "[" + content + "]";
        }
        return content;
    } else {
        return '"' + parameter.example + '"';
    }
}
function formatJson(txt, compress /*是否为压缩模式*/) {
    /* 格式化JSON源码(对象转换为JSON文本) */
    var indentChar = "    ";
    if (/^\s*$/.test(txt)) {
        return "";
    }
    try {
        var data = eval("(" + txt + ")");
    } catch (e) {
        console.error("json格式错误，无法格式化，格式化内容：" + txt);
        return txt;
    }
    var draw = [],
        last = false,
        This = this,
        line = compress ? "" : "\n",
        nodeCount = 0,
        maxDepth = 0;

    var notify = function (name, value, isLast, indent /*缩进*/, formObj) {
        nodeCount++; /*节点计数*/
        for (var i = 0, tab = ""; i < indent; i++)
            tab += indentChar; /* 缩进HTML */
        tab = compress ? "" : tab; /*压缩模式忽略缩进*/
        maxDepth = ++indent; /*缩进递增并记录*/
        if (value && value.constructor == Array) {
            /*处理数组*/
            draw.push(
                tab + (formObj ? '"' + name + '":' : "") + "[" + line
            ); /*缩进'[' 然后换行*/
            for (var i = 0; i < value.length; i++)
                notify(i, value[i], i == value.length - 1, indent, false);
            draw.push(
                tab + "]" + (isLast ? line : "," + line)
            ); /*缩进']'换行,若非尾元素则添加逗号*/
        } else if (value && typeof value == "object") {
            /*处理对象*/
            draw.push(
                tab + (formObj ? '"' + name + '":' : "") + "{" + line
            ); /*缩进'{' 然后换行*/
            var len = 0,
                i = 0;
            for (var key in value) len++;
            for (var key in value)
                notify(key, value[key], ++i == len, indent, true);
            draw.push(
                tab + "}" + (isLast ? line : "," + line)
            ); /*缩进'}'换行,若非尾元素则添加逗号*/
        } else {
            if (typeof value == "string") value = '"' + value + '"';
            draw.push(
                tab +
                (formObj ? '"' + name + '":' : "") +
                value +
                (isLast ? "" : ",") +
                line
            );
        }
    };
    var isLast = true,
        indent = 0;
    notify("", data, isLast, indent, false);
    return draw.join("");
}

buildChildrenTreeData.keyCounter = 0;
export function buildChildrenTreeData(arr) {
    arr.forEach((e, i)=>{
        e.key = buildChildrenTreeData.keyCounter++;
        if (e.refs && e.refs.length) {
            e.children = e.refs;
            e.children.forEach((r, m) => {
                r.key = "children" + buildChildrenTreeData.keyCounter++;
            });
            buildChildrenTreeData(e.refs);
        }
    });
}
