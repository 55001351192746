import instance from './axios'

export function  getDocBaseInfoApi(){
    return instance.get(`/openwebsite/doc/doc/getDocBaseInfo`)
}

export function  getDocBaseInfoInfactApi(){
    return instance.get(`/openwebsite/doc/doc/getDocBaseInfoInfact`) 
}

export function docinfoOrderApi (name='') {
    return instance.get(`/openwebsite/doc/doc/docinfo/${name}`)
}

export function testApi (params = {}) {
    return instance.post(`/openwebsite/doc/sandbox/test`, require('qs').stringify(params))
}

export function ssoApi (params = {}) {
    return instance.post('api/sso')
}

export function signOutApi () {
    return instance.post('api/sys/loginout')
}