<template>
  <div class="home">
    <div id="nav-box">
      <div class="nav">
        <h3>开放平台</h3>
        <div class="right">
          <span @click="selectBtn(item)" :class="{active: active == item.id}" v-for="item in list" :key="item.id">{{item.display}}</span>
          <span v-show="token" @click="selectBtn(item)" :class="{active: active == item.id}" v-for="item in tokenList" :key="item.id">{{item.display}}</span>
          <span v-show="token" @click="signOut">退出</span>
          <span v-show="!token" @click="login">登陆</span>
        </div>
      </div>
    </div>    
    <router-view/>
  </div>
</template>

<script>
import { signOutApi } from '@/serve/Interface'
import { clearCookies } from '@/tool'
export default {
  name: 'Home',
  data () {
    return {
      token: '',
      list: [
        {id: 0,name: 'SandBox' , display: '沙箱环境'},
        {id: 1,name: 'Doc' , display: '文档中心'},
      ],
      tokenList: [
        {id: 2, name: 'DocInfact' , display: '内部文档'}
      ],
      active: 0 
    }
  },
  mounted () {
    this.active = sessionStorage.active || 0
    this.token  = this.$cookies.get('token') || ''
  },
  methods: {
    login () {
      this.$router.push({name: 'Login'})
    },
    signOut () {
      signOutApi().then(res=>{
        if(res.code == 200 || res.code == 800){
          this.active = 0
          clearCookies()
          window.location.reload()
        }else{
          this.$message.error('登出失败')
        }
      }).catch(res=>{
        clearCookies()
        window.location.reload()
      });
    },
    selectBtn (item) {
      this.active = item.id
      sessionStorage.active = item.id
      this.$router.push({name: item.name})
    }
  }  
}
</script>
<style lang="scss">
  .home{
    width: 100%;
    height: 100%;
  }
  #nav-box{
    background: #333;
    min-width: 1200px;
    .nav{
      width: 1200px;
      height: 60px;
      line-height: 60px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      color: #999;
      h3{
        color: #fff;
      }
      .right{
        display: flex;
        span{
          display: block;
          padding: 0 20px;
          margin-left: 40px;
          cursor: pointer;
          &.active{
            border-bottom: 4px solid #5FB878;
            color: #fff;
          }
        }
      }
    }
  }  
</style>
