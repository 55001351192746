import axios from 'axios'
import Vue from 'vue'
const instance = axios.create({
 withCredentials: true,             // 设置cookie
 crossDomain: true,

});
instance.defaults.timeout = 20000       // 请求超时的时间限制

instance.interceptors.request.use(request =>{      // 接口发起请求
    request.headers['token'] = Vue.$cookies.get('token') || ''
    request.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    return request
}, error =>{
    console.log(error);
});

instance.interceptors.response.use(response => {   // 接口请求成功
    switch (response.data.code) {
        case 200:
            break
        case '10018':
            Vue.$cookies.remove('token')
            window.location.reload()
        default:
            break
    }
    return response.data
}, error =>{
    if(error && error.response){
        if(error.response.status === 401){      // 登陆失效
            localStorage.clear()
        }
    }
    return error.response.data
});

export default instance