import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import { clearCookies } from '@/tool'
import Home from '../views/Home.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'SandBox',
        component: () => import('../views/SandBox.vue')
      },
      {
        path: '/Doc',
        name: 'Doc',
        component: () => import('../views/Doc.vue')
      },
      {
        path: 'DocInfact',
        name: 'DocInfact',
        component: () => import('../views/DocInfact.vue')
      },
      {
        path: '/Sign',
        name: 'Sign',
        component: () => import('../views/Sign.vue')
      },
      {
        path: '/Auth',
        name: 'Auth',
        component: () => import('../views/Auth.vue')
      },
      {
        path: '/Code',
        name: 'Code',
        component: () => import('../views/Code.vue')
      }      
    ]
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }     
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async(to, from, next) => {
  if(to.path == '/Login'){
    clearCookies()
    return next()
  }

  if(to.path == '/DocInfact'){
    const hasToken = Vue.$cookies.get('token') || ''
    if(hasToken){
      next()
    }else{
      next({ path: '/' })
    }
  }else{
    next()
  }
})

export default router
