<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { ssoApi } from '@/serve/Interface'
import { clearCookies } from '@/tool'
export default {
  data () {
    return {
      type: 1
    }
  },
  watch: {
    $route (to, from) {
      if(to.name == 'DocInfact'){
        this.sso()
        this.ssoGet()
      }
    }
  },
  mounted (){
  },
  methods: {
    sso () {
      setInterval(this.ssoGet, 5000)
    },
    ssoGet () {
      ssoApi().then(res=>{
        if(res.code == 200){
          return
        }else if(res.code == 800){
          setTimeout(()=>{
            clearCookies()
            window.location.reload()
          }, 2000);
        }else if(res.code == 401){
          console.log(res.errorMsg)
          setTimeout(()=>{
            clearCookies()
            window.location.reload()
          },2000);
        }
      }).catch(res=>{
        this.open(res.errorMsg, 'error')
      })      
    }      
  }
}
</script>
<style lang="scss">
html,body{
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ff-url{
  display: flex;
  background: #fafafa;
  $color: #e8e8e8;
  padding: 5px 10px;
  border: 1px solid $color;
  .url-name{
    width: 60px;
    border-right: 1px solid $color;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;    
  }
  .url-url{
    width: calc(100% - 100px - 60px);
    padding: 0 10px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;    
  }
  .url-version{
    width: 60px;
    text-align: right;
    border-left: 1px solid $color;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;    
  }
}
</style>
